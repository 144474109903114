import WorkCard from "./WorkCard";
import { Stack } from "@mui/material";
import Section from "./Section";
import { useAppSelector } from "../Hooks/useStoreHooks";
import { useState } from "react";
import { ExperienceType } from "../Types/Types";
import GenericDialog from "./GenericDialog";

// type WorkSectioneProps = {};

export default function WorkSection() {
  const currenUserType = useAppSelector((state) => state.user.currenUserType);
  const [entryToShow, setEntryToShow] = useState<ExperienceType | undefined>();

  const showDialog = (entry: ExperienceType) => {
    setEntryToShow(entry);
  };

  const closeDialog = () => setEntryToShow(undefined);
  return (
    <Section
      name="Experience"
      description="Companies I have worked for in the past."
    >
      <Stack direction={{ xs: "column", md: "row" }} gap={2}>
        {currenUserType.experience.map((work, index) => {
          return (
            <WorkCard
              key={`work-card-${index}`}
              index={index}
              work={work}
              onClickLearnMore={showDialog}
            />
          );
        })}
      </Stack>
      {entryToShow && (
        <GenericDialog
          work={entryToShow}
          entryType="work"
          onClose={closeDialog}
        />
      )}
    </Section>
  );
}
