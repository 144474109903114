import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { Button } from "@mui/material";
import Logo from "./Logo";
import { ToggleColorModeSwitch } from "./ToggleColorModeSwitch";
import { LOCAL_STORAGE_THEME_KEY, sectionItems } from "../Consts/Consts";
import { useEffectOnce } from "../Hooks/useEffectOnce";
import { useState } from "react";
import { ToggleColorMode } from "../Features/Theme";
import { useAppDispatch, useAppSelector } from "../Hooks/useStoreHooks";
import { useMyNavigation } from "../Hooks/useMyNavigation";
import { useScrollToSection } from "../Hooks/useScrollToSection";

const drawerWidth = 240;

export default function Header() {
  const blogFT = useAppSelector((state) => state.featuresToggle.blogFT);
  const { push } = useMyNavigation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const dispatch = useAppDispatch();
  const scrollToSection = useScrollToSection();

  useEffectOnce(() => {
    const localTheme = localStorage.getItem(LOCAL_STORAGE_THEME_KEY);
    if (localTheme && (localTheme === "dark" || localTheme === "light")) {
      dispatch(ToggleColorMode(localTheme));
    }
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box
        display={"flex"}
        p={1}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Logo />
      </Box>

      <Divider />
      <List>
        {sectionItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton
              sx={{ textAlign: "center" }}
              onClick={() => scrollToSection(item)}
            >
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
        {blogFT && (
          <ListItem disablePadding>
            <ListItemButton
              sx={{ textAlign: "center" }}
              onClick={() => push("Blog")}
            >
              <ListItemText primary={"Blog"} />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <AppBar
        component="nav"
        sx={{
          px: { xs: 1, sm: 12 },
        }}
      >
        <Toolbar
          sx={{
            gap: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <IconButton
            name="menu"
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          <Box
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          >
            <Logo />
          </Box>

          <Box
            sx={{ display: { xs: "none", sm: "flex" } }}
            alignItems={"center"}
            gap={2}
            color={"white"}
          >
            {sectionItems.map((item, index) => (
              <Button
                key={`header-button-${index}`}
                name={item}
                sx={{ color: "white" }}
                onClick={() => scrollToSection(item)}
              >
                {item}
              </Button>
            ))}
          </Box>
          <ToggleColorModeSwitch />
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Toolbar sx={{ mb: 1 }} />
    </Box>
  );
}
