import { Avatar } from "@mui/material";
import logoLight from "../Assets/Images//logo-light.webp";
import logoDark from "../Assets/Images/logo-dark.webp";
import { useAppSelector } from "../Hooks/useStoreHooks";
import { useScrollToSection } from "../Hooks/useScrollToSection";

export default function Logo() {
  const themeMode = useAppSelector((state) => state.theme.themeColor);
  const relevantLogo = themeMode === "dark" ? logoDark : logoLight;
  const scrollToSection = useScrollToSection();

  return (
    <Avatar
      alt="Logo"
      src={relevantLogo}
      sx={{ cursor: "pointer" }}
      onClick={() => scrollToSection("Hero")}
    />
  );
}
