import { createSlice } from "@reduxjs/toolkit";

export const featuresToggleSlice = createSlice({
  name: "featureToggle",
  initialState: {
    toggleThemeModeFT: true,
    blogFT: false,
  },
  reducers: {
    setToggleThemeModeFT: (
      state,
      action: { payload: { newMode: boolean } }
    ) => {
      state.toggleThemeModeFT = action.payload.newMode;
    },
    setBlofFT: (state, action: { payload: { newMode: boolean } }) => {
      state.blogFT = action.payload.newMode;
    },
  },
});

export const { setToggleThemeModeFT } = featuresToggleSlice.actions;

export default featuresToggleSlice.reducer;
