import { Container, CssBaseline } from "@mui/material";
import Header from "./Component/Header";
import Footer from "./Component/Footer";
import { ThemeProvider } from "@mui/material";
import { customDefaultTheme, darkTheme } from "./Theme/Theme";
import { useAppSelector } from "./Hooks/useStoreHooks";
import LandingPage from "./Pages/LandingPage";
import { Routes, Route } from "react-router";

export default function App() {
  const themeColor = useAppSelector((state) => state.theme.themeColor);

  return (
    <ThemeProvider
      theme={themeColor === "dark" ? darkTheme : customDefaultTheme}
    >
      <Header />
      <CssBaseline />

      <Container
        component="main"
        id="app-wrapper"
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Routes>
          <Route path="/" element={<LandingPage />}></Route>
          <Route path="*" element={<LandingPage />}></Route>
        </Routes>
        <Footer />
      </Container>
    </ThemeProvider>
  );
}

// rel="noopener noreferrer"
