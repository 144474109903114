import { Chip } from "@mui/material";
import { ProjectStatusType } from "../Types/Types";
import BuildCircleRoundedIcon from "@mui/icons-material/BuildCircleRounded";
import PauseCircleFilledRoundedIcon from "@mui/icons-material/PauseCircleFilledRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import StopCircleRoundedIcon from "@mui/icons-material/StopCircleRounded";

type ProjectStatusProps = {
  projectStatus: ProjectStatusType;
};

export default function ProjectStatus({ projectStatus }: ProjectStatusProps) {
  let color:
    | "success"
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "warning";

  let icon;

  switch (projectStatus) {
    case "Active":
      color = "success";
      icon = <CheckCircleRoundedIcon fontSize="small" />;
      break;
    case "Discontinued":
      color = "error";
      icon = <StopCircleRoundedIcon fontSize="small" />;
      break;
    case "Building":
      color = "info";
      icon = <BuildCircleRoundedIcon fontSize="small" />;
      break;
    case "On hold":
      color = "secondary";
      icon = <PauseCircleFilledRoundedIcon fontSize="small" />;
      break;
    default:
      color = "default";
      break;
  }
  return (
    <Chip
      color={color}
      icon={icon ? icon : undefined}
      size="small"
      label={projectStatus}
    />
  );
}
