import Hero from "../Component/Hero";
import ProjectsSection from "../Component/ProjectsSection";
import SkillsSection from "../Component/SkillsSection";
import WorkSection from "../Component/WorkSection";

export default function LandingPage() {
  return (
    <>
      <Hero />
      <WorkSection />
      <SkillsSection />
      <ProjectsSection />
    </>
  );
}
