import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography/Typography";
import SocialNetworksNew from "./SocialNetworks";
import { sectionItems } from "../Consts/Consts";
import { Button, Divider, Stack } from "@mui/material";
import Logo from "./Logo";
import { useAppSelector } from "../Hooks/useStoreHooks";
import { useMyNavigation } from "../Hooks/useMyNavigation";
import { useScrollToSection } from "../Hooks/useScrollToSection";

const Copyright = () => {
  return (
    <Typography variant="caption" color="text.secondary">
      {"Copyright © "}
      Matan Avraham&nbsp;
      {new Date().getFullYear()}
    </Typography>
  );
};

export default function Footer() {
  const currenUserType = useAppSelector((state) => state.user.currenUserType);
  const blogFT = useAppSelector((state) => state.featuresToggle.blogFT);
  const scrollToSection = useScrollToSection();
  const { push } = useMyNavigation();

  return (
    <>
      <Divider flexItem />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mb: 2 }}>
        <Box display={"flex"} flex={1} justifyContent={"space-between"} p={1}>
          <Box
            display={"flex"}
            flexDirection="column"
            justifyContent={"center"}
            alignItems={"center"}
            gap={2}
          >
            <Logo />
            <SocialNetworksNew links={currenUserType.links} />
          </Box>
          <Box>
            <Stack>
              {sectionItems.map((item) => (
                <Button
                  key={item}
                  name={item}
                  variant="text"
                  onClick={() => scrollToSection(item)}
                >
                  {item}
                </Button>
              ))}
              {blogFT && (
                <Button name="blog" onClick={() => push("Blog")}>
                  Blog
                </Button>
              )}
            </Stack>
          </Box>
        </Box>

        <Box>
          <Typography variant="body2">Created by me &#128516;</Typography>
          <Copyright />
        </Box>
      </Box>
    </>
  );
}
