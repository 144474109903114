import Box from "@mui/material/Box/Box";
import { ExperienceType } from "../Types/Types";
import { Typography } from "@mui/material";

type WorkDialogProps = {
  work: ExperienceType;
};
const WorkDialog = ({ work }: WorkDialogProps) => {
  const workDates = `${work.startDate}-${work.endDate}`;
  return (
    <Box display={"flex"} flexDirection={"column"} gap={1}>
      <Typography variant="h4" color="primary">
        {work.companyName}
      </Typography>
      <Typography variant="h6">
        {work.role} | {workDates}
      </Typography>
      {work.bullets.map((bullet, index) => {
        return (
          <Box key={`work-bullet-${index}`} display={"flex"} gap={1}>
            <Box> &#8226;</Box>
            <Typography>{bullet}</Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default WorkDialog;
