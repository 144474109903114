import { Box, Dialog } from "@mui/material";
import { ExperienceType, ProjectType } from "../Types/Types";
import WorkDialog from "./WorkDialog";
import ProjectDialog from "./ProjectDialog";

interface GenericDialogProps {
  work?: ExperienceType;
  project?: ProjectType;
  entryType: "work" | "project";
  onClose: () => void;
}

export default function GenericDialog({
  work,
  project,
  entryType,
  onClose,
}: GenericDialogProps) {
  const closeHandler = () => {
    onClose();
  };

  return (
    <Dialog id="generic-dialog" onClose={closeHandler} open={true}>
      <Box sx={{ p: 3 }}>
        {work && entryType === "work" && <WorkDialog work={work} />}
        {project && entryType === "project" && (
          <ProjectDialog project={project} />
        )}
      </Box>
    </Dialog>
  );
}
