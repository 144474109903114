import Box from "@mui/material/Box/Box";
import { ProjectType } from "../Types/Types";
import { Divider, Typography } from "@mui/material";
import TechBox from "./TechBox";
import ProjectTitle from "./ProjectTitle";

type ProjectDialogProps = {
  project: ProjectType;
};

const ProjectDialog = ({ project }: ProjectDialogProps) => {
  const {
    name,
    status,
    star,
    description,
    brief,
    tech,
    websiteLink,
    githubLink,
  } = project;
  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      <ProjectTitle
        projectName={name}
        star={star || false}
        status={status}
        websiteLink={websiteLink || ""}
        githubLink={githubLink || ""}
      />
      <Typography>{description ? description : brief}</Typography>
      {tech && (
        <Box id="tech-box" display={"flex"} flexDirection={"column"} gap={0.5}>
          <Divider />
          {tech.front && (
            <TechBox description="Frontend" entries={tech.front} />
          )}
          {tech.back && <TechBox description="Backend" entries={tech.back} />}
          {tech.devOps && (
            <TechBox description="Dev-ops" entries={tech.devOps} />
          )}
          {tech.db && <TechBox description="Data-base" entries={tech.db} />}
          {tech.tests && <TechBox description="Tests" entries={tech.tests} />}
          {tech.style && <TechBox description="Style" entries={tech.style} />}
        </Box>
      )}
    </Box>
  );
};

export default ProjectDialog;
