import { Box, IconButton, Typography } from "@mui/material";
import ProjectStatus from "./ProjectStatus";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import { yellow } from "@mui/material/colors";
import { ProjectStatusType } from "../Types/Types";
import OpenInBrowserRoundedIcon from "@mui/icons-material/OpenInBrowserRounded";
import { openInNewTab } from "../Utils/utils";
import GitHubIcon from "@mui/icons-material/GitHub";

type ProjectTitleProps = {
  projectName: string;
  star: boolean;
  status: ProjectStatusType;
  websiteLink: string;
  githubLink: string;
};

export default function ProjectTitle({
  projectName,
  star,
  status,
  websiteLink,
  githubLink,
}: ProjectTitleProps) {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
      gap={2}
    >
      <Typography variant="h5" color="primary" fontWeight={"bold"}>
        {projectName}
      </Typography>
      <Box display={"flex"} alignItems={"center"} gap={2}>
        {websiteLink && (
          <IconButton
            name="open"
            size="small"
            onClick={() => openInNewTab(websiteLink)}
          >
            <OpenInBrowserRoundedIcon color="primary" />
          </IconButton>
        )}
        {githubLink && (
          <IconButton
            name="github"
            size="small"
            onClick={() => openInNewTab(githubLink)}
          >
            <GitHubIcon color="primary" />
          </IconButton>
        )}
        {star && (
          <StarRoundedIcon fontSize="small" sx={{ color: yellow[700] }} />
        )}
        <ProjectStatus projectStatus={status} />
      </Box>
    </Box>
  );
}
