import { IconButton } from "@mui/material";
import Box from "@mui/material/Box/Box";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import { SocialMediaLinksType } from "../Types/Types";
import { openInNewTab } from "../Utils/utils";

const ICON_BUTTON_SIZE: "small" | "medium" | "large" = "medium";
const ICON_COLOR:
  | "disabled"
  | "action"
  | "inherit"
  | "primary"
  | "secondary"
  | "error"
  | "info"
  | "success"
  | "warning" = "primary";
interface SocialNetworksProps {
  links: SocialMediaLinksType;
}

export default function SocialNetworks({ links }: SocialNetworksProps) {
  return (
    <Box
      display={"flex"}
      gap={1}
      alignItems={"center"}
      justifyContent={"center"}
    >
      {links.linkdein && (
        <IconButton
          name="linkedin"
          aria-label="linkedin"
          size={ICON_BUTTON_SIZE}
          onClick={() => openInNewTab(links.linkdein)}
        >
          <LinkedInIcon color={ICON_COLOR} fontSize={ICON_BUTTON_SIZE} />
        </IconButton>
      )}

      {links.github && (
        <IconButton
          name="github"
          aria-label="github"
          size={ICON_BUTTON_SIZE}
          onClick={() => openInNewTab(links.github)}
        >
          <GitHubIcon color={ICON_COLOR} fontSize={ICON_BUTTON_SIZE} />
        </IconButton>
      )}

      {links.youtube && (
        <IconButton
          name="youtube"
          aria-label="youtube"
          size={ICON_BUTTON_SIZE}
          onClick={() => openInNewTab(links.youtube)}
        >
          <YouTubeIcon color={ICON_COLOR} fontSize={ICON_BUTTON_SIZE} />
        </IconButton>
      )}

      {links.x && (
        <IconButton
          name="twitter"
          aria-label="twitter"
          size={ICON_BUTTON_SIZE}
          onClick={() => openInNewTab(links.x)}
        >
          <XIcon color={ICON_COLOR} fontSize={ICON_BUTTON_SIZE} />
        </IconButton>
      )}

      {links.instagram && (
        <IconButton
          name="instagram"
          aria-label="instagram"
          size={ICON_BUTTON_SIZE}
          onClick={() => openInNewTab(links.instagram)}
        >
          <InstagramIcon color={ICON_COLOR} fontSize={ICON_BUTTON_SIZE} />
        </IconButton>
      )}
    </Box>
  );
}
