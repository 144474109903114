import Typography from "@mui/material/Typography";
import { ProjectType } from "../Types/Types";
import Card from "@mui/material/Card/Card";
import { Button, CardActions, CardContent, Stack } from "@mui/material";
import ProjectTitle from "./ProjectTitle";

type ProjectCardProps = {
  project: ProjectType;
  onClickLearnMore: (work: ProjectType) => void;
};

export default function ProjectCard({
  project,
  onClickLearnMore,
}: ProjectCardProps) {
  const { name, status, star, brief, websiteLink, githubLink } = project;

  return (
    <Card
      elevation={4}
      sx={{
        p: 1,
        flex: 1,
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CardContent>
        <Stack gap={1}>
          <ProjectTitle
            projectName={name}
            star={star || false}
            status={status}
            websiteLink={websiteLink || ""}
            githubLink={githubLink || ""}
          />
          <Typography gutterBottom>{brief}</Typography>
        </Stack>
      </CardContent>
      <CardActions
        disableSpacing
        sx={{
          marginTop: "auto",
        }}
      >
        <Button
          variant="outlined"
          name="Learn more"
          size="small"
          onClick={() => {
            onClickLearnMore(project);
          }}
        >
          Learn more
        </Button>
      </CardActions>
    </Card>
  );
}
