import Box from "@mui/material/Box";
import { SvgIcon, Tooltip } from "@mui/material";
import { SiMui } from "react-icons/si";
import { FaReact } from "react-icons/fa";
import { FaHtml5 } from "react-icons/fa6";
import { BiLogoJavascript } from "react-icons/bi";
import { BiLogoTypescript } from "react-icons/bi";
import { SiRedux } from "react-icons/si";
import { FaAws } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { FaPython } from "react-icons/fa";
import { GrMysql } from "react-icons/gr";
import { BiLogoPostgresql } from "react-icons/bi";
import { SiJest } from "react-icons/si";
import { SiReactquery } from "react-icons/si";
import { SiReactrouter } from "react-icons/si";
import { SiAxios } from "react-icons/si";
import { RiOpenaiFill } from "react-icons/ri";
import { FaNodeJs } from "react-icons/fa";
import { SiMongodb } from "react-icons/si";
import { FaCss3 } from "react-icons/fa";
import { TechType } from "../Types/Types";
import { SiFirebase } from "react-icons/si";
import { FaCloudflare } from "react-icons/fa";
import { SiPostman } from "react-icons/si";
import { SiZod } from "react-icons/si";
import { FaJira } from "react-icons/fa";
import { FaNpm } from "react-icons/fa";

const logosObjectsArr: { name: TechType; icon: any; color: string }[] = [
  { name: "MUI", icon: <SiMui />, color: "#007fff" },
  { name: "React", icon: <FaReact />, color: "#087ea4" },
  { name: "HTML", icon: <FaHtml5 />, color: "#e44d26" },
  { name: "CSS", icon: <FaCss3 />, color: "#264de4" },
  { name: "JavaScript", icon: <BiLogoJavascript />, color: "#f0db4f" },
  { name: "TypeScript", icon: <BiLogoTypescript />, color: "#007acc" },
  { name: "Redux", icon: <SiRedux />, color: "#764abc" },
  { name: "AWS", icon: <FaAws />, color: "#ff9900" },
  { name: "GitHub", icon: <FaGithub />, color: "" },
  { name: "Python", icon: <FaPython />, color: "#3d79aa" },
  { name: "MySQL", icon: <GrMysql />, color: "#00618a" },
  { name: "PostgreSQL", icon: <BiLogoPostgresql />, color: "#306792" },
  { name: "Jest", icon: <SiJest />, color: "#99425b" },
  { name: "TanStack Query", icon: <SiReactquery />, color: "#ff4154" },
  { name: "React Router", icon: <SiReactrouter />, color: "#d0021b" },
  { name: "Axios", icon: <SiAxios />, color: "#5a29e4" },
  { name: "ChatGPT", icon: <RiOpenaiFill />, color: "#16a180" },
  { name: "Node.js", icon: <FaNodeJs />, color: "#90c53f" },
  { name: "Postman", icon: <SiPostman />, color: "#ff6c37" },
  { name: "Firebase", icon: <SiFirebase />, color: "#fccc2c" },
  { name: "MongoDB", icon: <SiMongodb />, color: "#4faa41" },
  { name: "Cloudflare", icon: <FaCloudflare />, color: "#f58021" },
  { name: "Zod", icon: <SiZod />, color: "#294f83" },
  { name: "Jira", icon: <FaJira />, color: "#2684fe" },
  { name: "npm", icon: <FaNpm />, color: "#c12127" },
];

const logoSize = 34;

export default function SkillsLogos() {
  return (
    <Box id="skills-logos">
      <Box
        display={"flex"}
        gap={4}
        py={1}
        pt={3}
        alignItems={"center"}
        justifyContent={"center"}
        flexWrap={"wrap"}
      >
        {logosObjectsArr.map((logoObj, index) => {
          return (
            <Tooltip key={`skill-logo-icon-${index}`} title={logoObj.name}>
              <SvgIcon
                key={`logo-icon-${index}`}
                sx={{
                  height: logoSize,
                  width: logoSize,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  transition: "200ms",
                  transitionTimingFunction: "ease-in-out",
                  outline: "1px solid transparent",
                  color: logoObj.color,
                  "&:hover": { scale: "1.25", color: logoObj.color },
                }}
              >
                {logoObj.icon}
              </SvgIcon>
            </Tooltip>
          );
        })}
      </Box>
    </Box>
  );
}
