import Typography from "@mui/material/Typography";
import { ExperienceType } from "../Types/Types";
import Card from "@mui/material/Card/Card";
import { Button, CardActions, CardContent } from "@mui/material";

const colorsArr = ["#3cc74e", "#609bff", "#e95d90", "#5221e6"];

type WorkCardProps = {
  index: number;
  work: ExperienceType;
  onClickLearnMore: (work: ExperienceType) => void;
};

export default function WorkCard({
  index,
  work,
  onClickLearnMore,
}: WorkCardProps) {
  return (
    <Card
      elevation={4}
      sx={{
        p: 1,
        flex: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CardContent>
        <Typography
          variant="h5"
          fontWeight="bold"
          color={colorsArr[index]}
          gutterBottom
        >
          {work.companyName}
        </Typography>
        <Typography variant="h6" gutterBottom>
          {work.role}
        </Typography>
        <Typography>{work.description}</Typography>
      </CardContent>
      <CardActions
        disableSpacing
        sx={{
          marginTop: "auto",
        }}
      >
        <Button
          variant="outlined"
          name="Learn more"
          size="small"
          onClick={() => {
            onClickLearnMore(work);
          }}
        >
          Learn more
        </Button>
      </CardActions>
    </Card>
  );
}
