import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography/Typography";
import { SectionsType } from "../Types/Types";
import { SECTION_PY } from "../Consts/Consts";

type SectionProps = {
  name: SectionsType;
  description: string;
  children: React.ReactNode;
};

export default function Section({ name, description, children }: SectionProps) {
  return (
    <Box id={name} py={SECTION_PY}>
      <Box display={"flex"} flexDirection={"column"} gap={3}>
        <Typography color={"text.secondary"} textTransform={"uppercase"}>
          {name}
        </Typography>
        <Typography variant="h4">{description}</Typography>
        {children}
      </Box>
    </Box>
  );
}
