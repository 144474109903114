import { SectionsType } from "../Types/Types";
import { tryScrollToSection } from "../Utils/utils";
import { useMyNavigation } from "./useMyNavigation";

export const useScrollToSection = () => {
  const { push } = useMyNavigation();

  const scrollToSection = (section: SectionsType) => {
    const isScrolled = tryScrollToSection(section);
    if (!isScrolled) {
      push("");
      setTimeout(() => {
        tryScrollToSection(section);
      }, 250);
    }
  };

  return scrollToSection;
};
