import { createSlice } from "@reduxjs/toolkit";
import { defaulUserType } from "../Consts/Consts";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    currenUserType: defaulUserType,
  },
  reducers: {},
});

// export const {} = userSlice.actions;

export default userSlice.reducer;
