import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import profilePic from "../Assets/Images/profilePic.webp";
import { useAppSelector } from "../Hooks/useStoreHooks";

export default function Hero() {
  const currenUserType = useAppSelector((state) => state.user.currenUserType);
  const { fullname, description, occupation } = currenUserType;
  return (
    <Box id="Hero" py={{ xs: 6, md: 16 }}>
      <Grid
        container
        direction={{ xs: "column", md: "row" }}
        alignItems={"center"}
      >
        <Grid
          item
          xs={5}
          display="flex"
          alignContent="center"
          justifyContent="center"
        >
          <Box
            sx={{
              minHeight: 100,
              maxWidth: 300,
              display: "flex",
              flex: 1,
              aspectRatio: 1,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundImage: `url('${profilePic}')`,
            }}
          />
        </Grid>
        <Grid
          item
          xs={7}
          sx={{
            p: 2,
          }}
        >
          <Stack gap={2}>
            <Typography
              variant="h3"
              fontWeight={"bold"}
              sx={{ flexWrap: "wrap" }}
            >
              I'm{" "}
              <span style={{ position: "relative" }}>
                <span
                  style={{
                    backgroundImage:
                      "linear-gradient(45deg,#a163f1,#6363f1 22%,#3498ea 40%,#40dfa3 67%, rgba(64, 223, 163, 0))",
                    backgroundSize: "150% 100%",
                    backgroundRepeat: "no-repeat",
                    backgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    width: "fit-content",
                  }}
                >
                  {fullname}.
                </span>
              </span>
            </Typography>

            <Typography variant="h4" fontWeight={"bold"}>
              A {occupation}
            </Typography>
            <Typography variant="h5">{description}</Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
