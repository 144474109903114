import SkillsLogos from "./SkillsLogos";
import Section from "./Section";

export default function SkillsSection() {
  return (
    <Section
      name="Skills"
      description={`In my journey, I've wielded an arsenal of tools, frameworks, programming languages, and more.`}
    >
      <SkillsLogos />
    </Section>
  );
}
