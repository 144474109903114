import { Box, Typography, Chip } from "@mui/material";

type TechBoxProps = {
  description: string;
  entries: string[];
};
export default function TechBox({ description, entries }: TechBoxProps) {
  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Typography variant="caption">{description}:</Typography>
      <Box display={"flex"} gap={0.5} flexWrap={"wrap"}>
        {entries.map((entry, index) => {
          return (
            <Chip
              key={`tech-chip-${index}`}
              size="small"
              label={entry}
              color="info"
            />
          );
        })}
      </Box>
    </Box>
  );
}
