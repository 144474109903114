import { Grid } from "@mui/material";
import Section from "./Section";
import ProjectCard from "./ProjectCard";
import { useAppSelector } from "../Hooks/useStoreHooks";
import { ProjectType } from "../Types/Types";
import { useState } from "react";
import GenericDialog from "./GenericDialog";

// type ProjectsSectionProps = {};

export default function ProjectsSection() {
  const currenUserType = useAppSelector((state) => state.user.currenUserType);
  const [entryToShow, setEntryToShow] = useState<ProjectType | undefined>();

  const showDialog = (entry: ProjectType) => {
    setEntryToShow(entry);
  };

  const closeDialog = () => setEntryToShow(undefined);
  return (
    <Section
      name="Projects"
      description="Work that I’ve done for the past 4 years."
    >
      <Grid container spacing={2} direction={{ xs: "column", md: "row" }}>
        {currenUserType.projects.map((project, index) => {
          return (
            <Grid
              key={`project-card-${index}`}
              item
              xs={6}
              display="flex"
              alignContent="center"
              justifyContent="center"
            >
              <ProjectCard project={project} onClickLearnMore={showDialog} />
            </Grid>
          );
        })}
      </Grid>
      {entryToShow && (
        <GenericDialog
          project={entryToShow}
          entryType="project"
          onClose={closeDialog}
        />
      )}
    </Section>
  );
}
