import WbSunnyRoundedIcon from "@mui/icons-material/WbSunnyRounded";
import NightlightRoundedIcon from "@mui/icons-material/NightlightRounded";

import { Paper, Switch } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../Hooks/useStoreHooks";
import { ToggleColorMode } from "../Features/Theme";

export const ToggleColorModeSwitch = () => {
  const toggleThemeModeFT = useAppSelector(
    (state) => state.featuresToggle.toggleThemeModeFT
  );
  const themeColor = useAppSelector((state) => state.theme.themeColor);
  const dispatch = useAppDispatch();
  if (!toggleThemeModeFT) return <></>;

  return (
    <Paper
      sx={{
        borderRadius: 100,
        py: 0.2,
        px: 0.4,
        display: "flex",
        alignItems: "center",
      }}
    >
      <NightlightRoundedIcon
        fontSize="small"
        color={themeColor === "dark" ? "primary" : "disabled"}
      />

      <Switch
        aria-label="toggle color mode"
        name="toggle color mode"
        size="small"
        color="primary"
        checked={themeColor === "light"}
        onClick={() => {
          dispatch(ToggleColorMode(themeColor === "dark" ? "light" : "dark"));
        }}
      />

      <WbSunnyRoundedIcon
        fontSize="small"
        color={themeColor === "light" ? "primary" : "disabled"}
      />
    </Paper>
  );
};
